<script setup async>
import { ref, onMounted } from "vue";
import { CounterAPI } from "counterapi";

const counterValue = ref(0);
const counter = new CounterAPI();

onMounted(async () => {
  const res = await counter.up("black12", "black12");
  counterValue.value = res.Count;
});
</script>
<template>
  <div
    class="relative mt-[6.7em] ml-[-4px] py-1"
  >
    <ul class="items-center space-x-8 hidden lg:flex h-5">
      <li class="bg-[#776849] px-2">
        <NuxtLink
          href="/"
          aria-label="Our product"
          title="Our product"
          class="font-medium tracking-wide text-[#0e0d0d] font-mono transition-colors duration-200 hover:text-teal-accent-400"
          >Black</NuxtLink
        >
      </li>
      <li>
        <NuxtLink
          to="/"
          aria-label="Our product"
          title="Our product"
          class="font-medium tracking-wide px-3 py-2 text-[#776849] font-mono transition-colors duration-200 hover:text-teal-accent-400"
          active-class="active-link "
          exact-active-class="exact-active-link"
          >Home</NuxtLink
        >
      </li>
      <li>
        <NuxtLink
          to="/about/"
          aria-label="Our product"
          title="Our product"
          class="font-medium tracking-wide px-3 py-2 text-[#776849] font-mono transition-colors duration-200 hover:text-teal-accent-400"
          active-class="active-link"
          exact-active-class="exact-active-link"
          >AboutMe</NuxtLink
        >
      </li>
      <li>
        <NuxtLink
          to="/project/"
          aria-label="Our product"
          title="Our product"
          class="font-medium tracking-wide px-3 py-2 text-[#776849] font-mono transition-colors duration-200 hover:text-teal-accent-400"
          active-class="active-link"
          exact-active-class="exact-active-link"
          >Projects</NuxtLink
        >
      </li>
      <li>
        <NuxtLink
          to="/guest"
          aria-label="Our product"
          title="Our product"
          class="font-medium tracking-wide px-3 py-2 text-[#776849] font-mono transition-colors duration-200 hover:text-teal-accent-400"
          active-class="active-link"
          exact-active-class="exact-active-link"
          >GuestZone</NuxtLink
        >
      </li>
      <li>
        <div class="views ml-[35em]">
          <a href="#" class="flex">
            <svg
              class="w-7 h-7 text-[#776849] dark:text-[#776849]"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                stroke-width="2"
                d="M21 12c0 1.2-4.03 6-9 6s-9-4.8-9-6c0-1.2 4.03-6 9-6s9 4.8 9 6Z"
              />
              <path
                stroke="currentColor"
                stroke-width="2"
                d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
              />
            </svg>
            <span
              class="flex items-center ml-2 mt-1 text-[#776849] font-mono text-sm font-bold"
            >
              {{ counterValue }}
            </span>
          </a>
        </div>
      </li>
    </ul>
  </div>
</template>

<style scoped>
.font-mono {
  font-family: "Courier New", Courier, monospace;
}

.active-link {
  background-color: #776849;
  color: #0e0d0d;
  padding: 4px;
}

.exact-active-link {
  background-color: #776849;
  color: #0e0d0d;
}
</style>
